import * as React from 'react';
import { graphql } from 'gatsby';
import { DefaultLayout } from '../../layouts/DefaultLayout';
import { PageTitle } from '../../components/Common/PageTitle';
import NewsList from '../../components/News/List';
import { Metadata } from '@mangoart/gatsby-ui/components/Metadata';

const StellenangebotePage = function ({ data, location }) {
  const { siteMetadata } = data.site;
  const { title, icon, metadata, opengraph } = data.page;

  return (
    <DefaultLayout>
      <div className="wrap">
        <PageTitle title={title} icon={icon} />
        <NewsList entries={data.news.edges} />
        <Metadata
          data={metadata}
          opengraphData={opengraph}
          defaultTitle={siteMetadata.title}
          canonicalSiteUrl={siteMetadata.canonicalSiteUrl}
          path={location.pathname}
        />
      </div>
    </DefaultLayout>
  );
};

export default StellenangebotePage;

export const StellenangebotePageQuery = graphql`
  query StellenausschreibungsPage($language: String) {
    site: site {
      siteMetadata {
        title
        canonicalSiteUrl
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    page: cockpitRegionStellenangebote(lang: { eq: $language }) {
      title
      icon
      metadata {
        title
        metakeywords
        metadescription
      }
      opengraph {
        title
        description
        type
        image {
          publicURL
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    news: allCockpitNews(
      filter: {
        sites: { in: "ezagrar.at" }
        category: { eq: "Stellenausschreibungen" }
        published: { eq: true }
        lang: { eq: $language }
      }
    ) {
      edges {
        node {
          id
          title
          teaser
          slug
          date
          expirationdate
          teaserimage {
            childImageSharp {
              gatsbyImageData(width: 400, aspectRatio: 2.2, placeholder: TRACED_SVG)
            }
          }
          images {
            alttext
            image {
              publicURL
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`;
